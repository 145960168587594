.banner-box {
  /* TODO: find the right amount for any screen size to maintain banner height whether there is a button or not */
  padding: 13px;
}

.banner-level-info {
  background-color: #bceafb;
  color: black;
}

.banner-level-warn {
  background-color: #ffe599;
  color: black;
}

.banner-level-danger {
  background-color: #ffc2bf;
  color: black;
}

.banner-button {
  padding-inline-start: 10px;
  /* TODO: find the right amount for any screen size to maintain banner height whether there is a button or not */
  margin: -6px 0;
}
