.qr-code-modal {
  /* Resetting global modal styles from variables.css */
  --border-radius: inherit;
  --width: inherit;
  --height: inherit;
  --min-width: inherit;
  --max-width: inherit;
  --border-width: inherit;
  --border-style: inherit;
  --border-color: inherit;

  /* Custom qr code modal styles */

  --border-radius: 1rem;
  --height: 90vh;
  --width: 80vw;
}

/* This rule is to remove the shadow under the modal header */
.qr-code-modal__header::after {
  background-image: none;
}
