.list-archive-panel {
  font-size: 1.125rem;
}

.list-archive-panel.disable {
  color: #bbb;
}

.list-archive-panel__checkbox {
  --border-color: var(--ion-color-dark);
  --border-color-checked: var(--ion-color-dark);
  --background-checked: var(--ion-color-dark);
  --size: 1.32rem;
}

.list-archive-panel__input-container {
  max-width: 56px;
  border: 1.32px solid #bbb;
  border-radius: 5.28px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.list-archive-panel__input-container.invalid {
  border-color: var(--ion-color-error);
}

.list-archive-panel__input {
  --padding-start: 0px;
  --padding-top: 5.28px;
  --padding-bottom: 5.28px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}

.list-archive-panel__input.invalid {
  --color: var(--ion-color-error);
}

/* This is to remove the arrows from input number */
/* Chrome, Safari, Edge, Opera */
.list-archive-panel__input input::-webkit-outer-spin-button,
.list-archive-panel__input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.list-archive-panel__input input[type='number'] {
  -moz-appearance: textfield;
}
