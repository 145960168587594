.list-import-errors-panel {
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  margin-bottom: 2rem;
  padding: 32px;
  background-color: #ffe1e1;
  font-size: 20px;
  min-height: 200px; /* Adjust the desired height */
}

.list-import-errors-table {
  margin-right: 32px;
  border-left: 1px solid var(--ion-color-light);
  border-top: 1px solid var(--ion-color-light);
  background-color: #fcfbfe;
  font-weight: bold;
}

.list-import-error-body-row {
  font-size: 14px;
}

.list-import-error-cell {
  border-right: 1px solid var(--ion-color-light);
  border-bottom: 1px solid var(--ion-color-light);
}
