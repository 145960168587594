.d-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.h-100 {
  height: 100%;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.v-center {
  top: 50%;
  transform: translateY(-50%);
}

.h-center {
  left: 50%;
  transform: translateX(-50%);
}
