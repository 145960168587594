.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

@media print {
  .print-hidden {
    display: none;
  }

  .print-content {
    display: block;
  }

  /* We need to remove the overlfow property in order to see the whoel content */
  body {
    position: static !important;
  }

  body.backdrop-no-scroll {
    overflow: auto !important;
  }

  .page-break {
    break-after: always;
  }
}

.disable-pointer-events {
  pointer-events: none;
}

.enable-pointer-events {
  pointer-events: initial;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.clickable {
  text-decoration: none;
  cursor: pointer;
}
