.qr-code-box {
  padding: 2.5rem;
  padding-top: 1rem;
}

.qr-code-box.medium {
  padding: 3.5rem;
  padding-top: 3rem;
}

.qr-code-box.large {
  padding: 5rem;
}

.qr-code-box__label {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: normal;
}

.qr-code-box__label.medium {
  font-size: 1rem;
}

.qr-code-box__label.large {
  font-size: 1.5rem;
}
