.grid-container {
  width: 95%;
}
.background-none {
  background: none;
}

ion-toast {
  --max-width: 250px;
}

#gridHeight.wj-flexgrid .wj-row .wj-cell {
  display: flex;
  align-items: center;
}

#gridHeight.wj-flexgrid .wj-row .wj-cell:not(.wj-header) {
  display: flex;
  align-items: center;
}

#gridHeight.wj-flexgrid .wj-row .wj-cell .wj-header {
  display: flex;
  align-items: center;
}

#gridHeight.wj-flexgrid .wj-row .wj-cell div {
  width: 100%;
  text-align: center;
}

.wj-cell.wj-header.people-list-grid_column-header {
  text-wrap: wrap;
  line-height: 16px;
}
