.wj-flexgrid .wj-cell span.wj-state-match {
  background: #e9ffd9;
}

.wj-flexgrid .wj-cell input[type='checkbox'] {
  accent-color: #1a7b58;
}

ion-select {
  --padding-start: 0.75rem;
  --padding-end: 0.75rem;
  --padding-top: 0.25rem;
  --padding-bottom: 0.25rem;
  border: 2px solid rgba(25, 28, 30, 0.87);
  border-radius: 0.25rem;
}

ion-select::part(icon),
ion-select::part(placeholder),
ion-select::part(text) {
  color: rgba(25, 28, 30, 0.87);
  opacity: 1;
}

ion-select::part(placeholder) {
  font-weight: 600;
}
