/*.indented-menu-item {*/
/*  margin-top: -10px;*/
/*  padding-left: 4.5em;*/
/*  font-size: 0.95em;*/
/*}*/

.floatBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.logo {
  margin-left: 1em;
  float: left;
  height: 3.25rem;
}

.logo-title {
  margin-top: 0.25em;
}

.avatar {
  height: 32px;
  width: 32px;
  margin: 0;
}

.profile-card {
  padding-left: 0em;
}

.username {
  text-transform: uppercase;
  font-weight: 600;
}

.last-login {
  text-transform: uppercase;
}

.menu-header-container {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
}

.menu-user-info {
  display: flex;
  /*gap: 0.15rem;*/
  flex-direction: column;
  /*vertical-align: middle;*/
}

.side-menu ion-item {
  --border-style: none;
  --background-hover: #f2ffe9;
  --background-hover-opacity: 1;
  --color-hover: #006c45;
}
