.custom-form ion-input,
.custom-form ion-select {
  --padding-bottom: 0.5rem;
  --padding-end: 0.5rem;
  --padding-start: 1rem !important;
  --padding-top: 0.5rem;
  border-radius: 0.5rem;
  border: 2px solid #aaaaaa;
  margin-top: 0.5rem;
}

form.custom-form ion-select::part(icon) {
  margin-top: 1rem;
  height: auto;
}

.welcome-form ion-input,
.welcome-form ion-select {
  width: 100%;
  max-width: 100%;
  --ripple-color: transparent;
}

.welcome-form ion-label {
  --color: #000000 !important;
  font-weight: bold;
  width: 8rem;
  flex: none;
}
.welcome-form ion-label:after {
  content: '*';
  color: red;
}

.welcome-form ion-item {
  --background-hover: none;
  --ripple-color: transparent;
}
