.custom-time-picker {
  background: var(--ion-color-step-300, #edeef0);
  font-size: inherit;
  font-family: inherit;
  appearance: none;
  border-radius: 8px;
  border: none;
  padding: 6px 12px;
  margin: 0px 2px;
}

.custom-time-picker:focus-visible {
  outline: none;
}

/* Hide the clock icon */
.custom-time-picker::-webkit-calendar-picker-indicator {
  color-scheme: ligth; /* Invert the icon color which is white be default */
}
.custom-time-picker::-webkit-inner-spin-button {
  /* display: none; */
}
.custom-time-picker::-webkit-clear-button {
  /* display: none; */
}

.custom-time-picker::-webkit-datetime-edit-hour-field:focus,
.custom-time-picker::-webkit-datetime-edit-minute-field:focus,
.custom-time-picker::-webkit-datetime-edit-second-field:focus,
.custom-time-picker::-webkit-datetime-edit-ampm-field:focus {
  background: #b3d3c7;
}

/* This is to make the popup theme light */
.custom-time-picker {
  color-scheme: light;
}
