.ion-select-search {
  --min-height: fit-content;

  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-dark);
  --border-radius: 0.25rem;

  cursor: pointer;
}

.ion-select-search--no-border {
  --border-style: none;
}

.ion-select-search__label {
  margin: 0;
  color: var(--ion-color-dark);
}

.ion-select-search__icon {
  transition: transform 0.25s ease-in-out;
  font-weight: 600;
}

.ion-select-search__icon--open {
  transform: rotate(180deg);
}

.ion-select-search__popover {
  --min-width: 0;
}

/* Disable scrollbar for the popover content */
/* chrome, safari */
.ion-select-search__popover-content::part(scroll)::-webkit-scrollbar {
  display: none;
}
/* firefox */
.ion-select-search__popover-content::part(scroll) {
  scrollbar-width: none;
}

.ion-select-search__popover-searchbar {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 1px solid var(--ion-color-light);
  border-bottom: 1px solid var(--ion-color-light);
}
