.success-message {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  gap: 8px;

  background: #dafdeb;
  border: 1px solid #007334;
  border-radius: 11px;

  margin-left: 3em;
  margin-right: 3em;
  margin-top: 1em;
}

.onboard-button {
  --border-radius: 5px;
  text-transform: uppercase;
}
