.employee-border {
  border: 1px solid #e6e6e6;
  border-radius: 0.5rem !important;
  /* max-height: 300px!important;   */
}
ion-toolbar.employee-toolbar {
  border-top: 1px solid #e6e6e6;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.border-bottom-radius {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

ion-searchbar.employee-searchbar {
  padding-left: 0 !important;
  padding-right: 0 !important;

  --box-shadow: 0 0 0 0 !important;
  border: 1px solid #e6e6e6;
  border-radius: 0.5rem !important;
}
ion-list.employee-list {
  /* border: 1px solid #e6e6e6; */
  max-height: 300px !important;
  overflow-y: auto;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  background: none;
  /* border-radius: .5rem; */
}

ion-item.employee-item {
  --border-radius: 0.5rem;
  margin: 0.5rem;
}

ion-item.selected {
  --background: #b3d3c7;
  --color: #fff;
}

ion-button.employee-button {
  --padding-start: 1rem;
  --padding-end: 1rem;
}

.favorite-unselected {
  color: #97a2a8;
}

.favorite-selected {
  color: #33896a;
}

.wj-content {
  border-radius: 1rem;
}

.wj-flexgrid .wj-cell {
  text-align: center;
  padding: 7px;
  border: none;
}

.wj-cell.wj-header {
  text-align: center;
}

.wj-cell,
.wj-cell ion-item::part(native) {
  background-color: #b3d3c7;
  --background: #b3d3c7;
  --inner-padding-end: 0;
}

.wj-cell.wj-alt,
.wj-cell.wj-alt ion-item::part(native) {
  background-color: #fff;
  --background: #fff;
  --inner-padding-end: 0;
}

.wj-cell.center-grid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timetracking-grid-visible-overflow {
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  cursor: pointer;
}

.custom-alert .alert-head {
  text-align: center;
}
.custom-alert .alert-wrapper {
  text-align: center !important;
  border-radius: 1rem;
}

.custom-alert .alert-button-group {
  justify-content: space-around;
}

.custom-alert .alert-button-cancel {
  border-radius: 1rem;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  background: var(--ion-color-primary);
}

.custom-alert .alert-button-confirm {
  color: var(--ion-color-danger);
}
