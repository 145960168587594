.search-bar {
  text-align: left;
}

.search-bar-container {
  margin-right: 0.5em;
  width: 50%;
}

.search-bar-container-flex {
  display: flex;
  margin-right: 0.5em;
  gap: 0.5rem;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.avatar {
  height: 32px;
}

.notification-button {
  font-size: 32px;
}

ion-popover {
  --min-width: 275px;
}
