ion-datetime.time-tracking {
  --background: var(--ion-color-ligth);
  --background-rgb: 255, 241, 242;

  box-shadow: rgba(var(--ion-color-light-rgb), 0.3) 0px 10px 15px -3px;

  --wheel-highlight-background: #006c452b;
  --wheel-fade-background-rgb: var(--ion-color-light-rgb);
}

.no-scrollbars {
  overflow: hidden;
}

.no-scrollbars * {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbars *::-webkit-scrollbar {
  display: none;
}
