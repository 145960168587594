.m-auto {
  margin: auto;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-left {
  margin-left: 0;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}
