.wj-cell.client-column {
  border: 0;
}

.wj-cell.client-column.hide-client {
  opacity: 0;
}

.wj-cell.auto-lists-column {
  padding: 0;
}

.wj-cell.auto-lists-column > div {
  height: 100%;
}

.auto-lists-cell {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}

.auto-lists-cell-enabled {
  background-color: var(--ion-color-primary);
  color: white;
}

.auto-lists-cell-disabled {
  background-color: white;
  color: var(--ion-color-primary);
}

.auto-lists-cell-enabled:hover {
  background-color: var(--ion-color-danger);
  opacity: 0.8;
}

.auto-lists-cell-disabled:hover {
  background-color: var(--ion-color-primary);
  opacity: 0.8;
  color: white;
}

.auto-lists-cell span.hover-text {
  display: none;
}

.auto-lists-cell:hover span.hover-text {
  display: block;
}

.auto-lists-cell:hover span.default-text {
  display: none;
}

.alert-button.alert-button-cancel {
  color: var(--ion-color-danger);
}
