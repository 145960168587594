/* remove red borders on invalid cells */
div.wj-cell.wj-state-invalid {
  border-top: none;
  border-left: none;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* increase the size of the red triangle in the upper right corner of invalid cells */
div.wj-cell.wj-state-invalid:not(.wj-header):after {
  position: absolute;
  content: '';
  width: 0;
  right: 0;
  top: -14px;
  border: 14px solid transparent;
  border-right-color: red;
}

/**
Shay does not like the asterisk on the Wijmo grid, so hiding it.
https://www.grapecity.com/wijmo/docs/GettingStarted/Glyphs

If in the future we want to show something else in place of what Wijmo does by default,
look at cell templates: https://www.grapecity.com/wijmo/demos/Grid/CustomCells/CellTemplates/Overview/react
*/
div.wj-flexgrid .wj-glyph-asterisk {
  display: none;
}

/* de-emphasize selected cells when the grid doesn't contain the focus */
div.wj-flexgrid:not(.wj-state-focused) .wj-state-selected,
div.wj-flexgrid:not(.wj-state-focused) .wj-state-multi-selected {
  /* border: 2px solid red; */
  background: #ddd;
  color: black;
}

.grid-buttons {
  width: 100%;
  margin-top: 0.5em;
  display: flex;
  flex-direction: row;
}

.btn-reset {
  order: 1;
}

.import-spacer {
  order: 2;
  flex-grow: 4;
}

.label-update {
  order: 3;
  margin: auto;
  margin-right: 0.5em;
}

.btn-import {
  order: 4;
}
