.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--ion-color-primary);
  border-radius: 32px;
  padding: 68px 8px;
  background-color: #f5f6f6;
  color: var(--ion-color-primary);
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  /* transition: border-color 0.3s ease; */
  min-height: 200px; /* Adjust the desired height */
}

.dropzone:hover {
  border-color: #888;
}

.dropzone p {
  margin-top: 10px;
  margin-bottom: 0px;
}

.cloud-icon {
  font-size: 42px;
  --ionicon-stroke-width: 42px;
}

ion-toast.list-import-toast {
  --max-width: auto !important;
  --width: fit-content;
}

ion-alert.list-import-alert {
  --max-width: 400px;
}

ion-alert.list-import-alert .alert-message {
  max-height: 100%;
}
