ion-item.people-page {
  --inner-padding-start: 1rem;
  --inner-padding-end: 1rem;
  --ripple-color: none;
  --padding-end: 0;
  text-align: center;
}

ion-item.people-page::part(native) {
  border-bottom-width: thin;
}

ion-item.item-active {
  --border-color: #006c45;
}
