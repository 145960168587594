/*wijmo grid global overrides*/

/*checkbox overrrides*/
.wj-flexgrid .wj-cell label input[type='checkbox'] {
  margin: 0px !important;
}
.wj-flexgrid input[type='checkbox'] {
  -webkit-appearance: none;
  padding: 6px;
  display: inline-block;
  position: relative;
  border: 1px solid #767676;
  border-radius: 0.125rem;
  width: 1rem;
  height: 1rem;
}
.wj-flexgrid input:indeterminate {
  -webkit-appearance: none;
}

.wj-flexgrid input[type='checkbox']:checked {
  -webkit-appearance: checkbox;
}

.wj-flexgrid input[type='checkbox']:indeterminate::before {
  content: '';
  border-radius: 0.125rem;
  top: -1px;
  left: -1px;
  position: absolute;
  display: block;
  color: white;
  width: 1rem;
  height: 1rem;
  background-color: #1a7b58;
}
.wj-flexgrid input[type='checkbox']:indeterminate::after {
  content: '';
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  left: 1px;
  margin: auto;
  border: solid white;
  border-width: 2px 0 0 0;
  position: absolute;
  margin: auto;
}
